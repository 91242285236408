<template>
    <div class="flex justify-center items-center" style="height:40%">
        <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
            <div class="text-center my-4">
                <h2 class="text-lg font-bold">
                    Editar Juzgado
                </h2>
            </div>
            <form @submit.prevent="update()">
              <div class="flex flex-wrap content-around">
                  <div class="w-full flex flex-col items-center py-5 px-2 ">
                      <div class="px-2 w-full flex flex-col">
                      <div class="mb-4" style="width: 100% !important;">
                          <dropdown
                          placeholder="Tipo de juzgado"
                          :options="juzgadoCategorias"
                          option-label="nombre"
                          option-value="id"
                          v-model="model.categoriaId"
                          class="w-full"
                          />
                          <MessageError :text="errors.categoriaId"/>
                      </div>
                      <div class="mb-4">
                          <dropdown
                          placeholder="Seleccione numero de juzgado"
                          class="w-full"
                          :options="noJuzgados"
                          option-label="nombre"
                          option-value="nombre"
                          v-model="model.code"
                          :filter="true"
                          />
                          <MessageError :text="errors.code"/>
                      </div>
                      </div>
                      <div class="px-2 w-full flex flex-col">
                      <div class="mb-4">
                          <AutoComplete
                          class="w-full"
                          inputClass="w-full"
                          v-model="model.nombre"
                          :suggestions="suggestions"
                          @complete="searchJuzgado($event)"
                          @item-select="selectJuzgado($event)"
                          field="nombre"
                          placeholder="Nombre del juzgado"
                          />
                          <MessageError :text="errors.nombre"/>
                      </div>
                      </div>
                      <div class="px-2 w-full flex flex-col">
                      <div class="mb-4">
                          <dropdown
                          placeholder="Seleccione departamento"
                          class="w-full"
                          :options="departamentos"
                          option-label="nomDpto"
                          option-value="codDpto"
                          v-model="model.departamentoId"
                          @change="getMpio"
                          :filter="true"
                          />
                          <MessageError :text="errors.departamentoId"/>
                      </div>
                      <div class="mb-4">
                          <dropdown
                          class="w-full"
                          placeholder="Seleccione municipio"
                          :options="municipios"
                          option-label="nomMpio"
                          option-value="codMpio"
                          v-model="model.municipioId"
                          :filter="true"
                          />
                          <MessageError :text="errors.municipioId"/>
                      </div>
                      </div>
                  </div>
                  <div class="w-full flex justify-between px-2 mb-9">
                      <Button  label="Cancelar"
                              class="bg-blue-900 p-button-outlined"
                              style="width: 244px;"
                              type="button"
                              @click="$router.go(-1)"
                      />
                      <Button  label="Editar"
                              class="bg-blue-900"
                              style="width: 244px;"
                              type="submit"
                      />
                  </div>
              </div>
            </form>
        </div>
    </div>
</template>
<script>
import juzgadoStore from '../../../../store/juzgado.store'
import juzgadoCategoriaStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/juzgadoCategoria.store'
import storeLocalizacion from '@/apps/pharmasan/juridica/procesosJuridicos/store/localizacion.store'
import JuzgadoService from '@/apps/pharmasan/juridica/procesosJuridicos/services/juzgado.service'
import NoJuzgadosService from '../../../../services/noJuzgados.service'
import { computed, onMounted, ref, watch } from 'vue'
import Swal from 'sweetalert2'
import { useRoute, useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { object, string, number } from 'yup'
export default {
  name: 'editarJuzgado',
  setup () {
    const _NoJuzgadosService = new NoJuzgadosService()
    const noJuzgados = ref([])
    onMounted(async () => {
      await storeLocalizacion.dispatch('getDeparatamentos')
      id.value = route.params.id
      _NoJuzgadosService.paginate().then(({ data }) => {
        noJuzgados.value = data.rows
      })
      await juzgadoCategoriaStore.dispatch('getJuzgadoCategorias')
      if (!juzgado.value.id) {
        juzgadoStore.dispatch('findJuzgado', route.params.id).then((data) => {
          model.nombre = data.nombre
          model.categoriaId = data.categoriaId
          model.code = data.code
          model.departamentoId = data.departamentoId
          model.municipioId = data.municipioId
          getMpio({ value: model.departamentoId }, model.municipioId)
        })
      }
    })
    /** intancias **/
    const _JuzgadoService = new JuzgadoService()
    const router = useRouter()
    const route = useRoute()
    const id = ref(null)
    const juzgado = computed(() => {
      return juzgadoStore.getters.juzgado
    })
    /** state **/
    const validationSchema = object({
      categoriaId: number().nullable().required('El campo es requerido').label(''),
      nombre: string().max(255).nullable().required('El campo es requerido').label(''),
      departamentoId: yup.string().required('El campo es requerido').label(''),
      municipioId: yup.string().required('El campo es requerido').label(''),
      code: string().required('El campo es requerido').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema
    })
    useField('nombre', null, {
      initialValue: juzgado.value.nombre
    })
    useField('categoriaId', null, {
      initialValue: juzgado.value.categoriaId
    })
    useField('departamentoId', null, {
      initialValue: juzgado.value.departamentoId
    })
    useField('municipioId', null, {
      initialValue: juzgado.value.municipioId
    })
    useField('code', null, {
      initialValue: juzgado.value.code
    })
    /** data **/
    const getMpio = ({ value }, municipioId = null) => {
      if (!value) {
        storeLocalizacion.commit('setMunicipios', [])
      } else {
        storeLocalizacion.dispatch('getMunicipios', value).then(() => {
          model.municipioId = municipioId
        })
      }
    }
    getMpio({ value: model.departamentoId }, model.municipioId)
    const suggestions = ref([])
    const selectedJuzgado = ref(juzgado.value.nombre)
    /** computed **/
    const juzgadoCategorias = computed(() => juzgadoCategoriaStore.getters._juzgadoCategorias)
    /** methodos **/
    const update = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      juzgadoStore.commit('setUpdateJuzgado', values)
      juzgadoStore.dispatch('updateJuzgado').then(({ data }) => {
        Swal.fire({
            title: 'Exito',
            text: 'Juzgado actuzalizado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
      })
    })
    const searchJuzgado = ({ query }) => {
      _JuzgadoService.search({ nombre: query }).then(({ data }) => {
        suggestions.value = data
      })
    }
    const selectJuzgado = ({ value }) => {
      if (!value) return
      model.id = value.id
      model.categoriaId = value.categoriaId
      model.nombre = value.nombre
      model.departamentoId = value.departamentoId
      model.code = value.code
      getMpio({ value: value.departamentoId }, value.municipioId)
    }
    const departamentos = computed(() => storeLocalizacion.getters._departamentos)
    const municipios = computed(() => storeLocalizacion.getters._municipios)
    watch(selectedJuzgado, (val) => {
      if (typeof val === 'string') {
        model.nombre = val
      }
    })
    /** hooks **/
    return {
      juzgadoCategorias,
      juzgado,
      errors,
      model,
      getMpio,
      departamentos,
      noJuzgados,
      municipios,
      searchJuzgado,
      suggestions,
      selectJuzgado,
      selectedJuzgado,
      update
    }
  }
}
</script>

<style scoped>

</style>
